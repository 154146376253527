import {useAuth} from "../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import Login from "./Login/Login";


export default function HeaderTools() {

    const {user, logout} = useAuth();
    const navigate = useNavigate();
    const [loggingIn, setLoggingIn] = useState(false);

    if (user) {
        return (
            <>
                {!document.location.href.includes("/slots") &&
                    <button className="button-action button-book" onClick={() => navigate('/slots')}>Book</button>
                }
                <button className="button-action" onClick={() => navigate('/profile')}>Profil</button>
                <button className="button-action button-cancel" onClick={() => {logout(); navigate('/');}}>Log ud</button>
        </>
        );
    }

    return (
        <>
            {!document.location.href.includes("/slots") &&
                <button className="button-action button-book" onClick={() => navigate('/slots')}>Book</button>
            }
            <button className="button-action button-confirm" onClick={() => setLoggingIn(true)}>Log ind</button>
            {loggingIn && <Login onComplete={(state) => { setLoggingIn(false); state && navigate('/'); }} />}
        </>
    )
}