import {useNavigate, useParams} from "react-router-dom";
import {useStripe} from "@stripe/react-stripe-js";
import {useBooky} from "../../hooks/useBooky";
import {getHost} from "../../utils/request-utils";
import {useEffect} from "react";


export default function PaymentReturn() {
    const params = useParams();
    const navigate = useNavigate();
    const stripe = useStripe();
    const {request} = useBooky();

    const bookingID = params.id;

    const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
    );

    const confirmBooking = async function() {
        try {
            let u = new URL(getHost() + "/api/v1/bookings/" + bookingID);
            const data = {
                action: 'FinalizeWithPayment',
            };

            await request('PATCH', u, data)
        } catch (error) {
            alert("Der skete en fejl: " + error);
        }
    }


    useEffect(() => {
        async function doStuff() {
            if (!clientSecret) {
                navigate('/');
                return;
            }

            if (!stripe) {
                // not yet loaded
                return;
            }

            const {paymentIntent} = await stripe.retrievePaymentIntent(clientSecret);

            switch (paymentIntent.status) {
                case "succeeded":
                    await confirmBooking();
                    navigate("/confirmation/" + bookingID);
                    return;
                default:
                    console.log("payment intent", paymentIntent);
                    document.getElementById('paymentError').innerText = "Status: " + paymentIntent.status;
                    break;
            }
        }
        doStuff();
    }, [stripe])


    return (<></>);
}