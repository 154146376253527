import {useAuth} from "../../hooks/useAuth";
import {forwardRef, useEffect, useMemo, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useBooky} from "../../hooks/useBooky";
import {useCompany} from "../../hooks/useCompany";
import {bookyRequest, getHost} from "../../utils/request-utils";
import {useImmer} from "use-immer";
import Login from "../Login/Login";
import {Interval} from "luxon";
import { DateTime } from "luxon";

const {Set, fromJS} = require('immutable');

export default function SlotsPage({onBook}) {

    const tz = "Europe/Copenhagen" // TODO: Get from location

    const {user} = useAuth();
    const {companyID, locationID, portalStyle, locations, setLocationID, setActivityID, activityID} = useCompany();
    const {request} = useBooky();
    const [theUser, setTheUser] = useState();

    let initialToday = DateTime.now().setZone(tz).startOf("day");

    const minDate = initialToday;
    const maxDate = initialToday.plus({days: 21});

    if (initialToday.hour > 16) {
        initialToday = initialToday.plus({days: 1});
    }

    if (initialToday < DateTime.fromISO("2023-01-21T00:00:00+01:00")) {
        initialToday = DateTime.fromISO("2023-01-21T00:00:00+01:00").setZone(tz);
    }

    const [today, setToday] = useState(initialToday);

    const [loadedSlots, setLoadedSlots] = useState({});
    const [knownResources, setKnownResources] = useState({});
    const [knownActivities, setKnownActivities] = useState({});
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    const [selectedUnixTimes, setSelectedUnixTimes] = useImmer(Set())
    const [selectedResources, setSelectedResources] = useImmer(Set())
    const [selectedActivityID, setSelectedActivityID] = useState(null);


    const thisWeek = useMemo(() => today.startOf('week').toFormat("yyyy-LL-dd"), [today]);

    useEffect(() => {
        let abort = false;
        const getData = async function () {
            if (!user) {
                setTheUser(null);
                return;
            }
            let u = new URL(getHost() + "/api/v1/login");
            let ret = await request('GET', u);
            if (abort) return;
            setTheUser(ret.data);
        }
        getData();
        return () => {
            abort = true
        }
    }, [user]);

    useEffect(() => {
        let abort = false;
        const getData = async function () {

            const start = today.startOf("week");
            const end = start.plus({days: 7});

            let u = new URL(getHost() + "/api/v1/slots");
            u.searchParams.set("companyID", companyID);
            u.searchParams.set("locationID", locationID);
            u.searchParams.set("from", start.toUTC().toISO());
            u.searchParams.set("to", end.toUTC().toISO());

            const bookingSlots = await request('GET', u.toString());
            if (abort) return;

            // sort the resource names
            bookingSlots.data.resources.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            const resources = {}
            bookingSlots.data.resources.forEach(res => {
                resources[res.id] = res;
            });

            const activities = {}
            bookingSlots.data.activities.forEach(res => {
                activities[res.id] = res;
            })

            // parse time fields
            bookingSlots.data.slots = bookingSlots.data.slots.map(slot => {
                // times are received with the local time zone
                slot.originalStartTime = slot.startTime;
                slot.startTime = DateTime.fromISO(slot.startTime).setZone(tz);
                slot.endTime = DateTime.fromISO(slot.endTime).setZone(tz);
                return slot;
            });

            const loaded = {}

            // map to days
            Interval.fromDateTimes(start, end).splitBy({days: 1}).forEach(day => {
                const list = bookingSlots.data.slots.filter(slot => {
                    return day.start.hasSame(slot.startTime, "day");
                });
                loaded[day.start.toUTC().toISO()] = {
                    slots: list,
                    resources: bookingSlots.data.resources,
                    activities: bookingSlots.data.activities,
                };
            });

            setLoadedSlots(loaded);
            setKnownResources(resources);
            setKnownActivities(activities);
        }
        getData();
        return () => {abort = true}
    }, [thisWeek, companyID, locationID])


    const setScrollClasses = function (el) {
        if (!el) {return}
        const isScrollable = el.scrollHeight > el.clientHeight || el.scrollWidth > el.clientWidth;
        if (!isScrollable) {
            el.classList.remove('is-bottom-overflowing', 'is-top-overflowing');
            return;
        }
        const isScrolledToBottom = el.scrollHeight <= el.clientHeight + el.scrollTop;
        const isScrolledToTop = el.scrollTop === 0;
        const isScrolledToRight = el.scrollWidth <= el.clientWidth + el.scrollLeft;
        const isScrolledToLeft = el.scrollLeft === 0;
        el.classList.toggle('is-bottom-overflowing', !isScrolledToBottom);
        el.classList.toggle('is-top-overflowing', !isScrolledToTop);
        el.classList.toggle('is-left-overflowing', !isScrolledToLeft);
        el.classList.toggle('is-right-overflowing', !isScrolledToRight);
    }

    useEffect(() => {
        setScrollClasses(document.querySelector('.scrollhint'));
        setTimeout(() => {window.scrollTo(0,0); setScrollClasses(document.querySelector('.scrollhint'))},10);
    }, [loadedSlots])


    const nextDate = function () {
        const newDate = today.plus({'days' : 1});
        if (newDate <= maxDate) {
            setSelectedDate(newDate);
        }
    }

    const prevDate = function () {
        const newDate = today.plus({'days': -1});
        if (newDate >= minDate) {
            setSelectedDate(newDate);
        }
    }

    const goToday = function() {
        removeSelection();
        setToday(initialToday);
    }

    const removeSelection = function() {
        setSelectedUnixTimes(Set());
        setSelectedResources(Set());
        setSelectedActivityID(null);
    }

    const setSelectedDate = function (date) {
        removeSelection();
        setToday(date);
    }

    const CustomDatePickerInput = forwardRef(({value, onClick}, ref) => (
        <div id="currentDate" onClick={onClick} ref={ref} style={{cursor: "pointer"}}>
            {today.toFormat("ccc yyyy-LL-dd", {locale: "da"})}
        </div>
    ));


    // calculate slots
    const bookingSlots = loadedSlots[today.toUTC().toISO()] || {slots: [], resources: [], activities: []};

    const earliestSlot = bookingSlots.slots.reduce((result, item) => {
        if (result === null || item.startTime < result) {
            result = item.startTime
        }
        return result;
    }, null);
    const latestSlot = bookingSlots.slots.reduce((result, item) => {
        if (result === null || item.startTime > result) {
            result = item.startTime
        }
        return result;
    }, null);

    const formatPrice = function (price, currency) {
        return new Intl.NumberFormat('da-DK', {style: 'currency', currency: currency}).format(price).toString();
    }

    const isAtEdge = function (startTime, endTime) {
        const commonEdges = Array.from(selectedUnixTimes).reduce((c, a) => {
            a = a.toObject();
            c += a.startTime.equals(endTime) ? 1 : 0;
            c += a.endTime.equals(startTime) ? 1 : 0
            return c;
        }, 0);
        return commonEdges === 1;
    }

    const isSlotRemovable = function (resourceID, startTime, endTime) { // times are Date objects
        const timeKey = fromJS({startTime: startTime, endTime: endTime});

        if (!selectedResources.has(resourceID)) {
            return false;
        }
        if (!selectedUnixTimes.has(timeKey)) {
            return false;
        }
        if (selectedResources.size === 1) {
            // check we do not make a hole in the selected times
            if (selectedUnixTimes.size !== 1 && !isAtEdge(startTime, endTime)) {
                return false;
            }
        }
        return true;
    }

    const isSlotSelectable = function (resourceID, startTime, endTime) {
        if (selectedUnixTimes.size === 0) {
            return true;
        }
        if (selectedResources.has(resourceID)) {
            // we are selecting further on an existing resource
            // check if we are at the end of an already selected time
            const isExpanding = Array.from(selectedUnixTimes).some(a => {
                a = a.toObject();
                return a.startTime.equals(endTime)
                    || a.endTime.equals(startTime);
            });
            if (!isExpanding) {
                return false;
            }

            // only select where all the resources are available for this time
            const matchingSlots = bookingSlots.slots.filter(s => {
                if (s.activityID !== selectedActivityID) {
                    return false;
                }
                if (!s.startTime.equals(startTime) || !s.endTime.equals(endTime)) {
                    return false;
                }
                const resourceMatch = selectedResources.intersect(s.resourcesAvailable.map(a => a.resourceID));
                return resourceMatch.size === selectedResources.size;
            });
            if (matchingSlots.length === 0) {
                return false;
            }
        } else {
            // we are selecting a new resource. Only select times that are next to times we have already selected
            const isExpanding = Array.from(selectedUnixTimes).some(a => {
                a = a.toObject();
                return a.startTime.equals(startTime)
                    && a.endTime.equals(endTime);
            })
            if (!isExpanding) {
                return false;
            }
            // Only select resources that has all the already selected times free
            const matchingSlots = bookingSlots.slots.filter(s => {
                if (s.activityID !== selectedActivityID) {
                    return false;
                }

                const matchKey = fromJS({
                    startTime: s.startTime,
                    endTime: s.endTime
                });
                if (!selectedUnixTimes.has(matchKey)) {
                    return false;
                }
                return s.resourcesAvailable.some(a => {
                    return a.resourceID === resourceID
                });
            });
            if (matchingSlots.length !== selectedUnixTimes.size) {
                return false;
            }
        }

        return true;
    }


    const toggleSlot = function (resource, slot, isSelected) {

        if (!slot) return;

        if (isSelected) {
            // deselect something
            if (!isSlotRemovable(resource.id, slot.startTime, slot.endTime)) {
                return;
            }
            if (selectedResources.size === 1) {
                if (selectedUnixTimes.size === 1) {
                    setSelectedResources(Set());
                    setSelectedUnixTimes(Set());
                    setSelectedActivityID(null);
                } else {
                    setSelectedUnixTimes(selectedUnixTimes.delete(fromJS({
                        startTime: slot.startTime,
                        endTime: slot.endTime
                    })));
                    setSelectedActivityID(slot.activityID);
                }
            } else {
                setSelectedResources(selectedResources.delete(resource.id));
            }
            if (selectedUnixTimes.size === 0 || selectedResources.size === 0) {
                setSelectedUnixTimes(Set());
                setSelectedResources(Set());
                setSelectedActivityID(null);
            }
        } else {
            // select something
            if (!isSlotSelectable(resource.id, slot.startTime, slot.endTime)) {
                return;
            }
            const insertTime = fromJS({
                startTime: slot.startTime,
                endTime: slot.endTime,
            });
            setSelectedUnixTimes(selectedUnixTimes.add(insertTime));
            setSelectedResources(selectedResources.add(resource.id));
            setSelectedActivityID(slot.activityID);
        }
    }

    const hasSelection = (selectedUnixTimes.size > 0 && selectedResources.size > 0);

    function slotState(resource, slot) {

        let ret = ["bookable-slot"];
        if (!slot) {
            return ret.join(" ");
        }


        if (hasSelection) {
            const hourCheck = fromJS({
                startTime: slot.startTime,
                endTime: slot.endTime,
            })
            const isHour = selectedUnixTimes.has(hourCheck);
            const isResource = selectedResources.has(resource.id);
            const isRemovable = isSlotRemovable(resource.id, slot.startTime, slot.endTime);

            if (isHour && isResource) {
                ret.push("selected");
            }
            if (!ret.includes("selected") && isSlotSelectable(resource.id, slot.startTime, slot.endTime)) {
                ret.push("selectable");
            }
            if (!ret.includes("selected") && !ret.includes("selectable")) {
                ret.push("unselectable");
            }
            if (isRemovable) {
                ret.push("removable");
            }
        } else {
            ret.push("initial");
        }

        return ret.join(" ");
    }

    function bookEvent(slot) {
        if (!user) {
            setIsLoggingIn(true)
        } else {
            const startTime = slot.startTime;
            const endTime = slot.endTime;
            const selectedResources = [];
            const activityID = slot.activityID;
            onBook({selectedResources, startTime, endTime, resources: knownResources, activityID, bookingEventID: slot.bookingEventID})
        }
    }

    function bookSelected() {
        if (!user) {
            setIsLoggingIn(true);
        } else {
            const times = selectedUnixTimes.toArray()
                .map(a => { return a.toObject()})
                .map(a => { return {startTime: a.startTime.toISO(), endTime: a.endTime.toISO()}})
                .sort((a, b) => {return a.startTime.localeCompare(b.startTime)});
            const startTime = times[0].startTime;
            const endTime = times[times.length-1].endTime;
            onBook({selectedResources, startTime, endTime, resources: knownResources, activityID: selectedActivityID})
        }
    }

    let totalPrice = 0;

    const displayedActivities = bookingSlots.activities?.filter(act => act.active && act.userBookable) ?? [];
    const displayedResources = bookingSlots.resources.filter(res => bookingSlots.activities.find(act => act.id === activityID)?.resourceGroupIDs.includes(res.resourceGroupID));

    const renderedBookingSlots = bookingSlots.slots.length && Interval.fromDateTimes(earliestSlot, latestSlot.plus({'minutes':1})).splitBy({hours:1}).map(hour => {
            return (
                <tr key={hour.start.toISO()}>
                    <td className="side-header">{hour.start.toFormat('HH:mm')}</td>
                    {displayedResources.map(item => {
                        const slot = bookingSlots.slots.find(slot => {
                            if (!Interval.fromDateTimes(slot.startTime, slot.endTime).contains(hour.start)) {
                                return false;
                            }
                            return !!slot.resourcesAvailable.some(a => a.resourceID === item.id);
                        })
                        const slotResource = slot ? slot.resourcesAvailable.find(a => a.resourceID === item.id) : undefined;

                        const classList = slotState(item, slot);

                        if (slotResource && classList.includes("selected")) {
                            totalPrice += slotResource.price;
                        }


                        if (slot && !knownActivities[slot.activityID].userBookable) {
                            return (
                                <td onClick={() => bookEvent(slot)}
                                    key={item.id} className="event">
                                    <span className="slot">
                                        <span>Åben Turnering</span>
                                    <span className="slotSubtext">Tilmeld her</span></span>
                                </td>
                            )
                        }

                        let priceText = "";
                        if (slotResource) {
                            priceText = formatPrice(slotResource.price, slotResource.currency);

                            if (!!theUser && theUser.showMemberPunchCard) {
                                if (slotResource.price === 50) {
                                    priceText = "1 klip"
                                } else if (slotResource.price === 100) {
                                    priceText = "2 klip"
                                }
                            }
                        }

                        return (
                            <td onClick={() => toggleSlot(item, slot, classList.includes("selected"))}
                                key={item.id} className={classList}>
                                {slotResource && (
                                    <span className="slot">{priceText}</span>
                                )}
                            </td>
                        )
                    })}
                </tr>
            )
        }
    );


    const isNotLocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone !== tz;

    const canObtainKey = !!theUser && (!theUser.userFields?.keyNumber && theUser.membershipID);

    return (
        <>
            {isLoggingIn && <Login onComplete={(state) => { setIsLoggingIn(false) }} />}
            <div className="detailsselector">
                <div>
                    <div>
                        Vælg tidspunkt og simulator<br/>
                        {!user && <span style={{fontSize: "80%"}} id="membershipHint">Log ind for at se medlemspriser og -tider.</span>}
                        {locations.length > 1 &&
                        <select onChange={(e) => {setLocationID(e.target.value)}}>
                            {locations.map(location => <option key={location.id} value={location.id}>{location.name}</option>)}
                        </select>
                        }
                        {displayedActivities.length > 1 &&
                            <select onChange={(e) => {setActivityID(e.target.value)}}>
                                {displayedActivities.map(activity => <option key={activity.id} value={activity.id}>{activity.name}</option>)}
                            </select>
                        }
                    </div>
                </div>
                <div id="datePicker">
                    <button id="btnPrevDate" className="button-action" onClick={prevDate}>&lt;</button>
                    <div>
                        <DatePicker
                            selected={today.setZone('local', {keepLocalTime: true}).toJSDate()}
                            onChange={date => setSelectedDate(DateTime.fromJSDate(date).setZone(tz).startOf('day'))}
                            locale="da"
                            minDate={minDate.setZone('local', {keepLocalTime: true}).toJSDate()}
                            maxDate={maxDate.setZone('local', {keepLocalTime: true}).toJSDate()}
                            dateFormat="eee yyyy-MM-dd"
                            todayButton="I dag"
                            customInput={<CustomDatePickerInput/>}
                        />
                    </div>
                    <button id="btnNextDate" className="button-action" onClick={nextDate}>&gt;</button>
                    <button id="btnNextDate" className="button-action button-today" onClick={goToday}>I dag</button>
                </div>
                <div id="bookStatus">
                    {hasSelection && (
                        <>
                            <span id="totalPrice">Total pris: {formatPrice(totalPrice, "DKK")}</span>
                            <button className="button-action button-cancel" onClick={removeSelection}>Fortryd</button>
                            <button className="button-action button-confirm" onClick={bookSelected}>Book!</button>
                        </>
                    )}
                </div>
            </div>
            {canObtainKey &&
                <div className="bottomDetails">Du har mulighed for at få en nøglebrik, som giver adgang til at booke udenfor bemandet åbningstid. Henvend dig i baren i åbningstiden for mere information.</div>
            }
            <div className="bookingcontainer scrollhint" onScroll={(e) => {setScrollClasses(e.currentTarget)}}>
                <table id="bookingtable" className="bookingtimes" cellSpacing="0">
                    {bookingSlots.slots.length === 0 && (
                        <tbody>
                        <tr>
                            <td style={{height: 80, textAlign: 'center'}}>Kun åbent for medlemmer, da centeret er ubemandet denne dag. Læs mere om <a href="/memberships">medlemskaber</a>, eller vælg en anden dato. Større arrangementer kan <a href="https://urban-golf.dk/kontakt">kontakte os</a>.</td>
                        </tr>
                        </tbody>
                    )}
                    {bookingSlots.slots.length > 0 && (
                        <tbody>
                        <tr>
                            <td className="common-header">Tid</td>
                            {displayedResources.map(item => {
                                const note = (item.name === "Sim 6" || item.name === "Sim 7" || item.name === "Sim 8") ? <span style={{color: "red"}}>*</span> : '';

                                return <td key={item.id} className="top-header">{item.name}{note}</td>
                            })}
                        </tr>
                        {renderedBookingSlots}
                        </tbody>
                    )}
                </table>
            </div>
            {bookingSlots.slots.length > 0 &&
                <div className="bottomDetails">
                    <span style={{color: "red"}}>*</span>: Sim 6, 7 og 8 er kun til højrehåndede. Sim 8 er mindre, og
                    kun til 1-2 personer. Se <a href="https://urban-golf.dk/simulatorer/" target="_blank"
                                                rel="noreferrer">oversigt</a> over simulatorer.
                    {isNotLocalTimezone && <>
                        <br/>Tiderne er vist med lokaltid for {tz}
                    </>
                    }
                </div>
            }
        </>

    )
}