import SlotsPage from "./SlotsPage";
import {useEffect, useState} from "react";
import SummaryPage from "./SummaryPage";
import {getHost} from "../../utils/request-utils";
import {useBooky} from "../../hooks/useBooky";
import {useAuth} from "../../hooks/useAuth";
import {DateTime} from "luxon";


export default function BookingPage() {

    const tz = "Europe/Copenhagen";

    const [isBooking, setIsBooking] = useState(false);
    const [bookingData, setBookingData] = useState({});
    const {request} = useBooky();
    const {user} = useAuth();
    const [checkingReservation, setCheckingReservation] = useState(true);


    // check for reservation
    useEffect(() => {
        let abort = false;
        const checkReservation = async function() {
            let u = new URL(getHost() + "/api/v1/login");
            let ret = await request('GET', u);
            if (abort) return;

            // check if we have a reservation
            u = new URL(getHost() + "/api/v1/reservations?userID=" + ret.data.id);
            ret = await request('GET', u);
            if (abort) return;
            if (ret.data && ret.data.length > 0) {
                // there is a reservation
                const booking = ret.data[0];
                setBookingData({
                    ...booking,
                    startTime: DateTime.fromISO(booking.start).setZone(tz).toISO(),
                    endTime: DateTime.fromISO(booking.end).setZone(tz).toISO(),
                    selectedResources: booking.resourceIDs,
                })
                setIsBooking(true);
            }
            setCheckingReservation(false);
        }
        if (user) {
            checkReservation();
        } else {
            setCheckingReservation(false);
        }
        return () => {abort = true}
    }, [])

    function onBook(data) {
        setIsBooking(true);
        setBookingData(data);
    }

    if (checkingReservation) {
        return (<></>);
    }

    return (
        <>
            {!isBooking && <SlotsPage onBook={onBook} />}
            {isBooking && <SummaryPage
                onBack={() => setIsBooking(false)}
                bookingData={bookingData}
                />}
        </>
    )

}