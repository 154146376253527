import {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";
import { useLocalStorage } from "./useLocalStorage";
import {bookyRequest, getHost} from "../utils/request-utils";
import ChangePassword from "../components/Profile/ChangePassword";
import {BookyProvider} from "./useBooky";
import {useCompany} from "./useCompany";

const AuthContext = createContext(null);

export const AuthProvider = ({ children, userData }) => {
    const {companyID} = useCompany();
    const [user, setUser] = useLocalStorage("user", userData);
    const [shouldChangePassword, setShouldChangePassword] = useState(false)

    const login = useCallback(async (data) => {

        // try to log in
        const d = {
            email: data.email,
            password: data.password,
            companyID: data.companyID || null,
            remember: true,
        };
        let u = new URL(getHost() + "/api/v1/login");
        const ret = await bookyRequest(null, 'POST', u, d);
        setUser(ret.data.accessToken);
        setShouldChangePassword(ret.data.shouldChangePassword || false);

    }, [setUser]);

    const logout = useCallback(() => {
        setUser(null);
    }, [setUser]);

    const value = useMemo(
        () => ({
            user,
            login,
            logout,
            setUser,
        }),
        [user, login, logout, setUser]
    );

    useEffect(() => {
        const checkData = async function() {
            if (!user) {
                return;
            }
            let u = new URL(getHost() + "/api/v1/login");
            let ret = await bookyRequest(user, 'GET', u, null);

            if (ret.data.companyID !== companyID) {
                logout();
            }
        }
        checkData();
    }, [])

    if (shouldChangePassword) {
        children =  (
            <AuthContext.Provider value={value}>
                <BookyProvider>
                    <div id="modalContainer">
                        <div className="loginModal modal">
                            <p>Vælg en ny adgangskode:</p>
                            <ChangePassword allowBack={false} onDone={() => setShouldChangePassword(false)} />
                        </div>
                    </div>
                </BookyProvider>
            </AuthContext.Provider>
        )
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
