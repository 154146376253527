import {createContext, useContext, useState} from "react";

const CompanyContext = createContext(null);

export const CompanyProvider = ({ children, initialCompanyID, initialPortalStyle, loadedActivities, loadedLocations}) => {
    const [companyID, setCompanyID] = useState(initialCompanyID);

    const [activities, setActivities] = useState(loadedActivities);
    const [locations, setLocations] = useState(loadedLocations);

    const [activityID, setActivityID] = useState(loadedActivities[0]?.id);
    const [locationID, setLocationID] = useState(loadedLocations[0]?.id);
    const [portalStyle, setPortalStyle] = useState(initialPortalStyle)

    const value = {
        companyID,
        locations,
        activities,
        activityID,
        locationID,
        setLocationID,
        setActivityID,
        portalStyle,
    };

    return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>;
};


export const useCompany = () => {
    return useContext(CompanyContext);
}