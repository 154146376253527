import {useEffect, useState} from "react";
import {getHost} from "../../utils/request-utils";
import {useCompany} from "../../hooks/useCompany";
import {useParams} from "react-router-dom";
import {useBooky} from "../../hooks/useBooky";
import {DateTime} from "luxon";
import ReactMarkdown from "react-markdown";


export default function ConfirmationPage() {

    const tz = "Europe/Copenhagen";

    let params = useParams();
    const bookingID = params.id;
    const {request} = useBooky();

    const {companyID, locationID, activityID} = useCompany();
    const [theUser, setTheUser] = useState();
    const [resources, setResources] = useState([]);
    const [punchCards, setPunchCards] = useState([]);
    const [activities, setActivities] = useState([]);
    const [addons, setAddons] = useState([]);
    const [booking, setBooking] = useState(undefined);

    function formatPrice(price, currency) {
        return new Intl.NumberFormat('da-DK', {style: 'currency', currency: currency}).format(price).toString();
    }

    useEffect(() => {
        const getData = async function () {
            let u = new URL(getHost() + "/api/v1/login");
            let ret = await request('GET', u);
            setTheUser(ret.data);
            const userID = ret.data.id;
            u = new URL(getHost() + "/api/v1/resources?companyID=" + companyID);
            ret = await request('GET', u);
            setResources(ret.data);
            u = new URL(getHost() + "/api/v1/bookings/" + bookingID);
            ret = await request('GET', u);
            setBooking(ret.data);
            u = new URL(getHost() + "/api/v1/activities?locationID=" + locationID);
            ret = await request('GET', u);
            setActivities(ret.data);
            u = new URL(getHost() + "/api/v1/addons?activityID=" + activityID);
            ret = await request('GET', u);
            setAddons(ret.data);
        }
        getData();
    }, []);

    if (!theUser || !booking) {
        return <></>;
    }

    const activity = activities?.find(a => a.id === booking.activityID);

    return (
        <>
            <div className="detailsselector">
                <div>
                    Bookingbekræftelse
                </div>
            </div>
            <div id="confirmationError" className="errorMessage"></div>
            <div className="summaryContainer">
                <div>

                    {activity?.description &&
                        <ReactMarkdown components={{h1:'h2'}}>
                            {activity.description}
                        </ReactMarkdown>
                    }

                    <h2>Din booking</h2>
                    <dl className="summaryList">
                        <dt>Navn</dt>
                        <dd id="bookingUserName">{theUser.name}</dd>
                        <dt>Email</dt>
                        <dd id="bookingUserEmail">{theUser.email}</dd>
                        <dt>Telefon</dt>
                        <dd id="bookingUserPhoneNumber">{theUser.phoneNumber}</dd>
                        <dt>Dato</dt>
                        <dd id="selectedDate">{DateTime.fromISO(booking.start).setZone(tz).toFormat("ccc yyyy-LL-dd",  {locale: "da"})}</dd>
                        <dt>Start</dt>
                        <dd id="selectedStartTime">{DateTime.fromISO(booking.start).setZone(tz).toFormat("HH:mm",  {locale: "da"})}</dd>
                        <dt>Slut</dt>
                        <dd id="selectedEndTime">{DateTime.fromISO(booking.end).setZone(tz).toFormat("HH:mm",  {locale: "da"})}</dd>
                        <dt>Type</dt>
                        <dd>{activity?.name}</dd>
                        {booking.resourceIDs?.length > 0 &&
                            <>
                            <dt>Plads</dt> <dd>
                            <ul className="inline-list" id="selectedResources">
                        {booking.resourceIDs?.map(sel => {
                            const res = resources.find(b => b.id === sel);
                            if (!res) return;
                            if (res.name === "Sim 6" || res.name === "Sim 7" || res.name === "Sim 8") {
                            return <li key={res.id}>{res.name} <span style={{color: "red"}}>(kun højrehåndede)</span></li>
                        }
                            return <li key={res.id}>{res.name}</li>
                        })}
                            </ul>
                            </dd>
                            </>
                        }
                        {booking.addonsSelected?.map((sel) => {
                            const addon = addons.find(a => a.id === sel.id);
                            return (<>
                                <dt>{addon?.title}</dt>
                                <dd>{sel.value}</dd>
                            </>)
                        })}
                    </dl>
                </div>
                <div>
                    <h2>Betaling</h2>
                    <dl className="summaryList">
                        <dt>Pris</dt>
                        <dd id="selectedPrice">{formatPrice(booking.price, booking.currency)}</dd>
                        <dt>Betalt</dt>
                        <dd id="selectedPayment">{booking.paymentState === 'Paid' ? 'Betalt' : 'Betales ved ankomst'}</dd>
                    </dl>
                </div>
                <div>
                    <h2>Information</h2>
                    <p>Du har også modtaget denne bekræftelse på email. Hvis du vil afbestille, kan du bruge linket i
                        emailen, eller logge ind på din profil her på siden.</p>
                    <p>Vi glæder os til at se dig!</p>
                </div>

            </div>
        </>
    )

}