import {useAuth} from "../../hooks/useAuth";
import {useEffect, useState} from "react";
import {useBooky} from "../../hooks/useBooky";
import {getHost} from "../../utils/request-utils";
import {useCompany} from "../../hooks/useCompany";
import {useImmer} from "use-immer";
import {useNavigate} from "react-router-dom";
import {Elements, useStripe} from "@stripe/react-stripe-js";
import {DateTime} from "luxon";
import Payment from "../Payment";
import ReactMarkdown from "react-markdown";

const {Set} = require('immutable');

export default function SummaryPage({onBack, bookingData}) {

    const tz = "Europe/Copenhagen";

    const {user} = useAuth();
    const {request, error} = useBooky();
    const {companyID, locationID} = useCompany();
    const navigate = useNavigate();
    const stripe = useStripe();

    const [theUser, setTheUser] = useState();
    const [resources, setResources] = useState([]);
    const [activities, setActivities] = useState([]);
    const [punchCards, setPunchCards] = useState([]);
    const [bookingID, setBookingID] = useState(bookingData.id || undefined);
    const [booking, setBooking] = useState(bookingData);
    const [allowedPunchCardIDs, setAllowedPunchCardIDs] = useImmer(Set());

    let initialAddons = {}
    bookingData.addonsSelected?.map(e => { initialAddons[e.id] = e.value})

    const [addonOptions, setAddonOptions] = useImmer(initialAddons);
    const [voucherCode, setVoucherCode] = useState("");
    const [paymentIntent, setPaymentIntent] = useState("");
    const [voucherCodeResult, setVoucherCodeResult] = useState("");
    const [newsletterSignup, setNewsletterSignup] = useState(false);

    useEffect(() => {
        let abort = false;
        const getData = async function () {
            let u = new URL(getHost() + "/api/v1/login");
            let ret = await request('GET', u);
            if (abort) return;
            setTheUser(ret.data);
            const userID = ret.data.id;
            u = new URL(getHost() + "/api/v1/resources?companyID=" + companyID);
            ret = await request('GET', u);
            if (abort) return;
            setResources(ret.data);
            u = new URL(getHost() + "/api/v1/punchCards?userID=" + userID);
            ret = await request('GET', u);
            if (abort) return;
            setPunchCards(ret.data);

            // try to pre-check the member punch card
            ret.data.forEach(e => {
                if (e.name === "Medlemsklip" && e.uses > 0) {
                    setAllowedPunchCardIDs(allowedPunchCardIDs.add(e.id));
                }
            })

            u = new URL(getHost() + "/api/v1/activities?locationID=" + locationID);
            ret = await request('GET', u);
            if (abort) return;
            setActivities(ret.data);
        }
        getData();
        return () => {
            abort = true
        }
    }, []);

    const getBookingData = function () {
        const addons = Object.keys(addonOptions).map(k => ({id:k, value: addonOptions[k]}));
        return {
            companyID: companyID,
            activityID: bookingData.activityID,
            locationID: locationID,
            start: bookingData.startTime,
            end: bookingData.endTime,
            userID: theUser.id,
            resourceIDs: Array.from(bookingData.selectedResources),
            allowedPunchCardIDs: Array.from(allowedPunchCardIDs),
            voucherCode: voucherCode,
            bookingEventID: bookingData.bookingEventID ?? null,
            addons: addons,
        };
    }

    const createBooking = async function () {
        try {
            if (!theUser) return;
            const data = getBookingData();
            const u = new URL(getHost() + "/api/v1/bookings" + (bookingID !== undefined ? '/' + bookingID : ''));
            const ret = await request(bookingID !== undefined ? 'PUT' : 'POST', u, data);

            setBookingID(ret.data.id);
            setBooking(ret.data);
            setVoucherCodeResult("");
            if (ret.data.usedVoucherCode) {

                let voucherDescription = "";
                if (ret.data.usedVoucherType === "Money") {
                    voucherDescription = "Gavekortet " + ret.data.usedVoucherCode + " betaler for " + formatPrice(ret.data.usedVoucherAmount, booking.currency) + " ("+ formatPrice(ret.data.usedVoucherBalance - ret.data.usedVoucherAmount, booking.currency)+" tilbage)";
                } else {

                    voucherDescription = "Koden " + ret.data.usedVoucherCode + " er brugt: " + ret.data.usedVoucherName;
                    if (ret.data.usedVoucherBalance > ret.data.usedVoucherAmount ) {
                        voucherDescription += " ("+(ret.data.usedVoucherBalance - ret.data.usedVoucherAmount)+" tilbage)";
                    }

                }
                setVoucherCodeResult(voucherDescription)
            } else {
                if (voucherCode) {
                    setVoucherCodeResult("Koden er ikke gyldig");
                }
            }
        } catch (error) {
            switch (error.message) {
                case "Booking Policy Violated":
                    if (error.details.includes("EventSeatFilter: No seats left")) {
                        alert('Der er desværre ikke flere pladser til denne turnering.');
                        navigate('/');
                        break;
                    }
                    if (error.details.includes("EventSignupFilter: Already signed up")) {
                        alert('Du er allerede tilmeldt denne turnering.');
                        navigate('/');
                        break;
                    }

                    const dkMessage = error.details.find(elm => elm.startsWith("DKMessage: "))?.replace('DKMessage: ','');

                    alert('Dit medlemskab tillader ikke dette antal samtidige bookinger. ' + dkMessage??'');
                    navigate('/');
                    break;
            }
        }
    }

    const removeReservation = async function () {
        if (!bookingID) {
            return
        }
        setBookingID(undefined);
        let u = new URL(getHost() + "/api/v1/bookings/" + bookingID);
        await request('DELETE', u);
    }

    const signupForNewsletter = async function () {
        if (!newsletterSignup) {
            return;
        }
        if (!theUser.email.includes('@')) {
            return;
        }
        let u = new URL(getHost() + "/api/v1/mailingListSignup");
        const data = {
            email: theUser.email,
        };
        await request('POST', u, data);
    }

    const confirmBooking = async function () {
        await signupForNewsletter();
        let u = new URL(getHost() + "/api/v1/bookings/" + bookingID);
        const data = {
            action: "FinalizeNoPayment",
        };
        await request('PATCH', u, data);
        navigate("/confirmation/" + bookingID);
    }

    const startPayment = async function () {
        await signupForNewsletter();
        let u = new URL(getHost() + "/api/v1/payments");
        const data = {
            action: "PayForBooking",
            bookingID: bookingID,
        };

        const ret = await request('POST', u, data)

        setPaymentIntent(ret.data.paymentIntent);
    }

    async function goBack() {
        await removeReservation();
        onBack();
    }

    useEffect(() => {
        const a = async function () {
            await createBooking()
        };
        a();
    }, [allowedPunchCardIDs, theUser, addonOptions])

    async function handleChangePunchCard(punchCard) {
        if (allowedPunchCardIDs.has(punchCard.id)) {
            setAllowedPunchCardIDs(allowedPunchCardIDs.remove(punchCard.id));
        } else {
            setAllowedPunchCardIDs(allowedPunchCardIDs.add(punchCard.id));
        }
    }

    async function handleChangedAddon(addonID, e) {
        setAddonOptions({...addonOptions, [addonID]: e.target.value});
    }

    function formatPrice(price, currency) {
        if (!currency) {
            return price;
        }
        return new Intl.NumberFormat('da-DK', {style: 'currency', currency: currency}).format(price).toString();
    }

    if (!theUser) {
        return <></>
    }

    const activity = activities?.find(a => a.id === bookingData.activityID);

    return (
        <>
            {paymentIntent && <Elements stripe={stripe} options={{clientSecret: paymentIntent}}><Payment
                paymentIntent={paymentIntent}
                returnPath={"/paymentReturn/" + booking.id}
                onClose={() => setPaymentIntent("")}
            /></Elements>}
            <div className="detailsselector">
                <div>Opsummering</div>
                <div>
                    <button className="button-action button-cancel" onClick={goBack}>Tilbage</button>
                </div>
            </div>
            {error && <div id="summaryError" className="errorMessage">{error}</div>}
            <div className="summaryContainer reverseSmall">
                <div className="moveLastMobile">

                    {activity?.description &&
                            <ReactMarkdown components={{h1:'h2'}}>
                                {activity.description}
                            </ReactMarkdown>
                    }

                    <h2>Din booking</h2>
                    <dl className="summaryList">

                        <dt>Navn</dt>
                        <dd id="bookingUserName">{theUser.name}</dd>
                        <dt>Email</dt>
                        <dd id="bookingUserEmail">{theUser.email}</dd>
                        <dt>Telefon</dt>
                        <dd id="bookingUserPhoneNumber">{theUser.phoneNumber}</dd>

                        <dt>Dato</dt>
                        <dd id="selectedDate">{DateTime.fromISO(bookingData.startTime).setZone(tz).toFormat("ccc yyyy-LL-dd", {locale: "da"})}</dd>
                        <dt>Start</dt>
                        <dd id="selectedStartTime">{DateTime.fromISO(bookingData.startTime).setZone(tz).toFormat("HH:mm", {locale: "da"})}</dd>
                        <dt>Slut</dt>
                        <dd id="selectedEndTime">{DateTime.fromISO(bookingData.endTime).setZone(tz).toFormat("HH:mm", {locale: "da"})}</dd>
                        <dt>Type</dt>
                        <dd>{activity?.name}</dd>
                        {bookingData.selectedResources?.length > 0 &&
                            <>
                                <dt>Plads</dt>
                                <dd>
                                    <ul className="inline-list" id="selectedResources">
                                        {bookingData.selectedResources?.map(sel => {
                                            const res = resources.find(b => b.id === sel);
                                            if (!res) return;
                                            if (res.name === "Sim 6" || res.name === "Sim 7" || res.name === "Sim 8") {
                                                return <li key={res.id}>{res.name} <span style={{color: "red"}}>(kun højrehåndede)</span>
                                                </li>
                                            }
                                            return <li key={res.id}>{res.name}</li>
                                        })}
                                    </ul>
                                </dd>
                            </>
                        }

                    </dl>
                </div>

                <div>
                    <h2>Rabatkoder {booking.addons?.length > 0 && "& Tilkøb"}</h2>
                    <div id="discountCodeForm">
                        <p>Hvis du har en rabatkode, kan du taste den her:</p>
                        <label id="discountCodeLabel" htmlFor="discountCode">Rabatkode</label>
                        <input type="text" id="discountCode" value={voucherCode} onChange={(e) => {
                            setVoucherCode(e.target.value)
                        }}/>
                        <span id="discountCodeResult" className="errorMessage">{voucherCodeResult}</span>
                        <button id="btnApplyDiscountCode" className="button-action" onClick={() => createBooking()}>Brug
                            rabatkode
                        </button>
                    </div>

                    {punchCards.length > 0 && <>
                        <p id="punchCardDescription">Du har følgende rabatter til rådighed, som du kan vælge at bruge.
                            Tryk på en rabat for at bruge den.</p>
                        <ul id="punchCards">
                            {punchCards.sort((a,b) => a.name.localeCompare(b.name)).map(punchCard => {

                                const usage = booking.usedPunchCards && booking.usedPunchCards.find(a => a.punchCardID === punchCard.id);

                                return (<li key={punchCard.id}>
                                    <label htmlFor={punchCard.id}>
                                        <input id={punchCard.id} type="checkbox"
                                               onChange={() => handleChangePunchCard(punchCard)}
                                               checked={allowedPunchCardIDs.has(punchCard.id)}/>
                                        {punchCard.usageType === "Money" && <>
                                            {punchCard.name} - {formatPrice(punchCard.uses, booking.currency)} til rådighed.
                                            {usage && <span className="usedPunchCard">Bruger {formatPrice(usage.count, booking.currency)}</span>}
                                        </>}
                                        {punchCard.usageType === "Slots" && <>
                                            {punchCard.name} - {punchCard.uses} til rådighed.
                                            {usage && <span className="usedPunchCard">Bruger {usage.count} klip</span>}
                                        </>}
                                    </label>
                                </li>);
                            })}
                        </ul>
                    </>}


                    {booking.addons?.length > 0 && <>
                        <ul className="addons">
                        {booking.addons.map(addon => (
                            <li key={addon.id}>
                                <label className="addonLabel" htmlFor={addon.id}>{addon.title}</label>
                                <select className="addonSelect" name={addon.id} onChange={(e) => handleChangedAddon(addon.id, e)} defaultValue={addonOptions[addon.id]}>
                                    <option value="">-</option>
                                    {addon.options?.sort((a,b) => a.value.localeCompare(b.value)).map(o => <option key={o.key} value={o.key}>{o.value}</option>)}
                                </select>
                                <em>{addon.description}</em>
                            </li>
                        ))}
                        </ul>
                    </>}

                </div>

                <div>
                    <h2>Bekræft</h2>
                    <p>Når du booker hos Urban Golf accepterer du samtidig vores <a
                        href="https://urban-golf.dk/handelsbetingelser/" target="_blank">handelsbetingelser</a>.</p>

                    <label htmlFor="newsletterSignup" style={{marginBottom: "20px", display: "block"}}>
                        <input type="checkbox" id="newsletterSignup" value={newsletterSignup}
                               onChange={(e) => setNewsletterSignup(e.target.value)}/>
                        Ja tak, jeg vil gerne modtage nyheder og tilbud fra Urban Golf. Jeg kan altid framelde mig igen.
                    </label>

                    {booking.addonsSelected?.filter(addon => addon.id === '0c762250-cb89-580c-59ce-89a96ff98fae').map(addon => (
                        <p style={{marginBottom: "20px"}}><em>Bemærk: Denne booking indeholder flere timer end dit medlemskab tillader. Prisen for ekstra timer er lagt til.</em></p>
                    ))}


                    <div style={{fontSize: "120%", fontWeight: 600}}>Samlet
                        pris: {bookingID && formatPrice(booking.price, booking.currency)}</div>

                    {theUser && theUser.showMemberPunchCard && booking.price > 0 &&
                        <div style={{marginTop: "20px"}}>Hvis du ikke har medlemsklip nok, men får tildelt flere klip før denne spilletid, så vælg "Book og betal ved fremmøde". Når du får klip tildelt, vil de blive brugt til denne booking.</div>
                    }


                    {booking && booking.paymentOptions && (
                        <>
                            {booking.paymentOptions.length === 0 &&
                                <button className="button-action button-confirm button-payment"
                                        onClick={confirmBooking}>Book!</button>}
                            {booking.paymentOptions.includes('OnArrival') &&
                                <button className="button-action button-confirm button-payment"
                                        onClick={confirmBooking}>Book og betal ved fremmøde</button>}
                            {booking.paymentOptions.includes('Stripe') &&
                                <button className="button-action button-confirm button-payment"
                                        onClick={startPayment}>Book med online betaling</button>}
                        </>)}
                </div>
            </div>
        </>
    );

}