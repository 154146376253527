import {useBooky} from "../../hooks/useBooky";
import {useEffect, useState} from "react";
import {useCompany} from "../../hooks/useCompany";
import {useNavigate} from "react-router-dom";
import {getHost} from "../../utils/request-utils";
import {useImmer} from "use-immer";
import ChangePassword from "./ChangePassword";
import EditProfile from "./EditProfile";
import {DateTime} from "luxon";
import {Elements, useStripe} from "@stripe/react-stripe-js";
import Payment from "../Payment";


export default function ProfilePage() {

    const tz = "Europe/Copenhagen";

    const {request, error, clearError} = useBooky();
    const {companyID, locationID, activityID} = useCompany();
    const navigate = useNavigate();
    const stripe = useStripe();

    const [theUser, setTheUser] = useState();
    const [membership, setMembership] = useState();
    const [bookings, setBookings] = useImmer([]);
    const [resources, setResources] = useState([]);
    const [activities, setActivities] = useState([]);
    const [punchCards, setPunchCards] = useState([]);
    const [addons, setAddons] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [editingProfile, setEditingProfile] = useState(false);
    const [changingPassword, setChangingPassword] = useState(false);
    const [paymentIntent, setPaymentIntent] = useState("");
    const [membershipLinks, setMembershipLinks] = useState([]);


    const getMembership = async function (id) {
        let u = new URL(getHost() + "/api/v1/memberships/" + id);
        const ret = await request('GET', u);
        return ret.data;
    }

    const getBookings = async function (userID) {
        let u = new URL(getHost() + "/api/v1/bookings?userID=" + userID);
        const ret = await request('GET', u);
        return ret.data ?? [];
    }

    const getResources = async function (companyID) {
        let u = new URL(getHost() + "/api/v1/resources?companyID=" + companyID);
        const ret = await request('GET', u);
        return ret.data;
    }

    const getPunchCards = async function (userID) {
        const u = new URL(getHost() + "/api/v1/punchCards?userID=" + userID);
        const ret = await request('GET', u);
        return ret.data ?? [];
    }

    const getMembershipLinks = async function(userID) {
        const u = new URL(getHost() + "/api/v1/userMembershipLinks/" + userID);
        const ret = await request('GET', u);
        return ret.data ?? [];
    }

    useEffect(() => {
        let abort = false;
        const getData = async function () {
            let u = new URL(getHost() + "/api/v1/login");
            const ret = await request('GET', u);
            if (abort) return;
            setTheUser(ret.data);

            if (ret.data.membershipID) {
                setMembership(await getMembership(ret.data.membershipID));
            }
            if (abort) return;
            setResources(await getResources(companyID));
            if (abort) return;
            const bookings = await getBookings(ret.data.id);
            bookings.sort((b,a) => { return b.start.localeCompare(a.start)});
            setBookings(bookings);
            if (abort) return;
            setPunchCards(await getPunchCards(ret.data.id));
            if (abort) return;
            setMembershipLinks(await getMembershipLinks(ret.data.id));
            u = new URL(getHost() + "/api/v1/activities?locationID=" + locationID);
            const activityData = await request('GET', u);
            if (abort) return;
            setActivities(activityData.data);
            u = new URL(getHost() + "/api/v1/addons?activityID=" + activityID);
            const addonData = await request('GET', u);
            if (abort) return;
            setAddons(addonData.data);
        }
        getData();
        return () => {abort = true}
    }, [])

    function formatPrice(price, currency) {
        return new Intl.NumberFormat('da-DK', {style: 'currency', currency: currency}).format(price).toString();
    }

    async function deleteBooking(bookingID) {

        if (window.confirm("Er du sikker på at du vil afbestille denne booking?") === false) {
            return false;
        }

        setErrorMessage("");
        try {
            let u = new URL(getHost() + "/api/v1/bookings/" + bookingID);
            const data = {
                action: 'Cancel',
                refundPayments: true,
            };
            const ret = await request('PATCH', u, data);

            setBookings(draft => {
                const index = draft.findIndex(a => a.id === bookingID);
                if (index !== -1) draft.splice(index, 1)
            })


        } catch (error) {
            switch (error.message) {
                case "Too late to cancel booking":
                    setErrorMessage("Det er for sent at afbestille denne booking. Kontakt os på info@urban-golf.dk eller telefon 93 98 00 17 for at afbestille.");
                    clearError();
                    window.scrollTo(0,0);
                    break;
            }
            return false;
        }

    }

    function showEditProfile() {
        setErrorMessage("");
        setEditingProfile(true);
    }

    function showChangePassword() {
        setErrorMessage("");
        setChangingPassword(true);
    }



    async function goToStripePortal() {
        const u = new URL(getHost() + "/api/v1/stripeLink")
        const data = {action: "PortalLink"};
        const ret = await request('POST', u, data);
        document.location.href = ret.data.link;
    }

    const buyPunchCard = async function() {
        let u = new URL(getHost() + "/api/v1/payments");
        const data = {
            action: "BuyPunchCard",
            punchCardID: "2ae65e43-4ea4-4afe-bbeb-ecba568f825a",
            count: 10,
        }
        const ret = await request('POST', u, data);

        setPaymentIntent(ret.data.paymentIntent);
    }

    const prolongLockerRental = async function() {
        const u = new URL(getHost() + "/api/v1/stripeLink")
        const data = {action: "ProlongLockerRental"};
        const ret = await request('POST', u, data);
        document.location.href = ret.data.link;
    }

    if (!theUser) {
        return <></>
    }

    return (
        <>
            {paymentIntent && <Elements stripe={stripe} options={{clientSecret: paymentIntent}}><Payment
                paymentIntent={paymentIntent}
                returnPath="/profile"
                onClose={() => setPaymentIntent("")}
            /></Elements>}
            <div className="detailsselector">
                <div>
                    Profil
                </div>
                <div>
                    <button className="button-action button-cancel" id="btnBack" onClick={() => navigate('/')}>Tilbage
                    </button>
                </div>
            </div>
            {error && <div id="profileError" className="errorMessage">{error}</div>}
            {errorMessage && <div id="profileError" className="errorMessage">{errorMessage}</div>}
            <div className="summaryContainer" id="profileContainer">

                {editingProfile &&
                    <EditProfile
                        onDone={() => setEditingProfile(false)}
                        user={theUser}
                        setUser={setTheUser}
                        />
                }
                {changingPassword &&
                    <ChangePassword onDone={() => setChangingPassword(false)} />
                }

                {!editingProfile && !changingPassword &&
                    <div id="profileFormHook">
                        <h2>Profil</h2>
                        <dl className="summaryList">
                            <dt>Navn</dt>
                            <dd id="profileName">{theUser.name}</dd>
                            <dt>Email</dt>
                            <dd id="profileEmail">{theUser.email ?? ''}</dd>
                            <dt>Telefon</dt>
                            <dd id="profilePhoneNumber">{theUser.phoneNumber ?? ''}</dd>
                            <dt>Medlemskab</dt>
                            <dd id="profileMembership">
                                {membershipLinks.length > 0 && <>Sammen med {membershipLinks.map(elm => elm.name)}</>}
                                {membershipLinks.length === 0 && (membership ? membership.name : <em>Intet</em>)}
                            </dd>
                            {theUser.userFields && theUser.userFields.keyNumber && <>
                                <dt id="profileKeyNumberLabel">Nøglenummer</dt>
                                <dd id="profileKeyNumber">{theUser.userFields.keyNumber}</dd>
                            </>}
                            {theUser.userFields && theUser.userFields.lockerNumber && <>
                                <dt id="lockerNumberLabel">Skabsnummer</dt>
                                <dd id="lockerNumber">#{theUser.userFields.lockerNumber} - <em>udløber {theUser.userFields.lockerEndDate}</em></dd>
                            </>}
                        </dl>
                        <div className="buttonActions">
                        <button className="button-action" id="btnEditProfile" onClick={showEditProfile}>Ret profil
                        </button>
                        <button className="button-action" id="btnChangePassword" onClick={showChangePassword}>Skift
                            adgangskode
                        </button>
                        {!membership && membershipLinks.length === 0 &&
                            <button className="button-action" onClick={() => navigate('/memberships')}>Køb medlemskab</button>
                        }
                        {membership && membership.canAccessStripePortal &&
                            <button className="button-action" onClick={goToStripePortal}>Se medlemskab</button>
                        }
                        {membership && (membership.name === "Opstartsmedlem" || membership.name === "Basismedlem") &&
                            <button className="button-action" onClick={() => navigate('/memberships')}>Køb medlemskab</button>
                        }
                        {theUser.userFields && theUser.userFields.lockerNumber &&
                            <button className="button-action" onClick={prolongLockerRental}>Forlæng skabsleje</button>
                        }
                        {!membership && membershipLinks.length === 0 && new Date() >= new Date('2024-10-01') &&
                            <>
                            <button className="button-action" onClick={buyPunchCard}>Køb 10 klip til 1.000,-</button>
                                Læs mere om klippekort <a href="https://urban-golf.dk/klippekort">her.</a>
                            </>
                        }
                        </div>

                        {punchCards.length > 0 &&
                            <div id="discounts" style={{marginTop: 20}}>
                                <h2>Rabatter</h2>
                                <p>Du har følgende rabatter til rådighed:</p>
                                <ul id="profilePunchCards">
                                    {punchCards.sort((a,b) => a.name.localeCompare(b.name)).map(punchCard =>
                                        (<li key={punchCard.id}>
                                            {punchCard.usageType === "Money" && <>{punchCard.name} - {formatPrice(punchCard.uses, "DKK")} til rådighed.</>}
                                            {punchCard.usageType === "Slots" && <>{punchCard.name} - {punchCard.uses} klip til rådighed.</>}
                                        </li>)
                                    )}

                                </ul>
                            </div>
                        }
                    </div>
                }


                <div>
                    <h2>Fremtidige bookinger</h2>
                    <p>Betalte bookinger kan afbestilles op til 2 timer før starttidspunktet for fuld refundering af
                        betaling.
                        Eventuelt brugte klippekort og rabatkoder kan bruges igen til en anden booking.</p>
                    <ul id="bookings">
                        {bookings.map(booking => {
                            const res = booking.resourceIDs?.map(a => {
                                return resources.find(b => b.id === a).name;
                            });
                            return (
                                <li key={booking.id}>
                                    <dl className="summaryList">
                                        <dt>Type</dt>
                                        <dd>{activities?.find(a => a.id === booking.activityID)?.name}</dd>
                                        <dt>Dato</dt>
                                        <dd>{DateTime.fromISO(booking.start).setZone(tz).toFormat("ccc yyyy-LL-dd",  {locale: "da"})}</dd>
                                        <dt>Start</dt>
                                        <dd>{DateTime.fromISO(booking.start).setZone(tz).toFormat("HH:mm",  {locale: "da"})}</dd>
                                        <dt>Slut</dt>
                                        <dd>{DateTime.fromISO(booking.end).setZone(tz).toFormat("HH:mm",  {locale: "da"})}</dd>

                                        {res && <>
                                        <dt>Plads</dt>
                                        <dd>{res?.join(", ")}</dd>
                                        </>
                                        }
                                        {booking.addonsSelected?.map((sel) => {
                                            const addon = addons.find(a => a.id === sel.id);
                                            return (<>
                                                <dt>{addon?.title}</dt>
                                                <dd>{sel.value}</dd>
                                            </>)
                                        })}
                                        <dt>Pris</dt>
                                        <dd>{formatPrice(booking.price, booking.currency)}</dd>
                                    </dl>
                                    <button className="button-action button-cancel"
                                            onClick={() => deleteBooking(booking.id)}>Afbestil
                                    </button>
                                </li>
                            )
                        })}

                    </ul>
                </div>
            </div>
        </>
    )

}