import {getHost} from "../../utils/request-utils";
import {useBooky} from "../../hooks/useBooky";
import { useForm } from "react-hook-form";


export default function ChangePassword({onDone, allowBack = true}) {

    const {request} = useBooky();
    const { register, clearErrors, handleSubmit, formState: { errors } } = useForm();


    async function savePassword(inputData) {
        clearErrors();
        const newPassword = inputData.password;
        const u = new URL(getHost() + "/api/v1/changePassword");
        const data = {
            newPassword: newPassword,
        };
        try {
            await request('POST', u, data);
        } finally {
            alert('Din adgangskode er nu skiftet');
            onDone();
        }
    }

    return (
        <div>
            <form className="loginForm loginModal" id="profileForm" onSubmit={handleSubmit(savePassword)} noValidate>
                <label htmlFor="password">Ny adgangskode</label>
                <input type="password" {...register('password', {required: true})}/>
                <span className="errorMessage">
                {errors.password?.type === 'required' && <>Vælg en adgangskode</>}
                </span>
                <button id="btnSetNewPassword" type="submit" className="button-action button-confirm">Gem
                </button>
                {allowBack &&
                    <button id="btnCancelForm" className="button-action button-cancel"
                            onClick={() => onDone()}>Tilbage
                    </button>
                }
            </form>
        </div>
    )
}