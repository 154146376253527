import {useBooky} from "../hooks/useBooky";


export const Main = ({ children }) => {

    const {loading} = useBooky();

    return (
        <div id="main" className={loading ? 'spin' : undefined}>{children}</div>
    )
}