import * as Sentry from "@sentry/react";

export async function bookyRequest(accessToken, method, url, body = null) {
    const data = {
        method: method,
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'omit',
        headers: {
            'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    };

    if (accessToken !== undefined) {
        data.headers["Authorization"] = accessToken;
    }
    if (body) {
        data.body = JSON.stringify(body);
    }
    const response = await fetch(url, data);

    if (response.ok) {
        const text = await response.text();
        if (text) {
            return JSON.parse(text);
        } else {
            return Error("no text in response")
        }
    } else {
        if (response.status === 401) {
            // auth error, log out
            document.location.href = '/';
            throw Error("Not logged in");
        }

        Sentry.captureMessage("Response error from server " + method + " " + url + " " + response.status, {
            level: "info",
            tags: {
                url: url,
                responseStatus: response.status,
                method: method,
            }
        })

        if (response.status >= 400 && response.status <= 499) {
            const text = await response.text();
            if (text) {
                const errorData = JSON.parse(text);
                throw errorData.error;
            }
        }
        throw Error("Unknown Error");
    }
}

export function getHost() {
    if (window.location.host.includes("localhost")) {
        return "http://localhost:3101";
    }
    return "https://api.bookysoft.com";
}