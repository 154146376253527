import {getHost} from "../../utils/request-utils";
import {useCompany} from "../../hooks/useCompany";
import {useBooky} from "../../hooks/useBooky";
import { useForm } from "react-hook-form";

export default function EditProfile({onDone, user, setUser}) {

    const {request, clearError} = useBooky();
    const {companyID} = useCompany()
    const {register, handleSubmit, setError, formState: {errors}} = useForm();


    async function editProfile(inputData) {

        const data = {
            ...inputData,
            language: 'da',
            companyID: companyID,
        };

        try {

            let u = new URL(getHost() + "/api/v1/users/" + user.id);
            const ret = await request('PUT', u, data);
            setUser(ret.data);
            alert('Din profil er opdateret');
            onDone();
        } catch (error) {
            switch (error.message) {
                case "User Already Exists":
                    setError('email', {type: 'taken'});
                    clearError();
                    break;
                default:
                    throw error;
            }
        }

    }

    return (
        <div>
            <form className="loginForm loginModal" id="profileForm" onSubmit={handleSubmit(editProfile)} noValidate>
                <label htmlFor="name">Navn</label>
                <input defaultValue={user.name} className={errors.name ? 'has-error' : null} type="text" {...register('name', {required: true})}/>
                <span className="errorMessage">
                    {errors.name?.type === 'required' && <>Navn skal angives</>}
                </span>

                <label htmlFor="email">Email</label>
                <input defaultValue={user.email} className={errors.email ? 'has-error' : null} type="email" {...register('email', {required: true})}/>
                <span className="errorMessage">
                    {errors.email?.type === 'required' && <>Email skal angives</>}
                    {errors.email?.type === 'taken' && <>Der findes allerede en bruger med denne email</>}
                </span>

                <label htmlFor="phoneNumber">Telefon</label>
                <input defaultValue={user.phoneNumber} className={errors.phoneNumber ? 'has-error' : null} type="text" {...register('phoneNumber', {required: true})} />
                <span className="errorMessage">
                        {errors.phoneNumber?.type === 'required' && <>Telefonnummer skal angives</>}
                        </span>

                <button id="btnSaveProfile" type="submit" className="button-action button-confirm">Gem</button>
                <button id="btnCancelForm" className="button-action button-cancel"
                        onClick={onDone}>Tilbage
                </button>
            </form>
        </div>
    )

}