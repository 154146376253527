import {loadStripe} from '@stripe/stripe-js';
import {useEffect, useMemo, useState} from "react";
import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";

export default function Payment({paymentIntent, returnPath, onClose}) {

    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();


    async function handleSubmit(e) {
        e.preventDefault();
        setLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: window.location.origin + returnPath,
                payment_method_data: {
                    billing_details: {
                        address: {
                            country: 'DK',
                        }
                    }
                },
            },
        });

        if (error.type === "card_error" || error.type === "validation_error") {
            setErrorMessage(error.message);
        } else {
            setErrorMessage("Der skete en fejl");
        }
        setLoading(false);
    }

    const stripeOptions = useMemo(() => {
        return {
            layout: "tabs",
                fields: {
            billingDetails: {
                address: {
                    country: 'never',
                }
            }
        }
        }
    }, [paymentIntent])


    return (
        <div id="modalContainer" className={loading ? "spin" : null}>
            <div className="paymentModal modal">
                <button className="close-button" onClick={onClose}>&#x00d7;</button>
                <form id="payment-form" onSubmit={handleSubmit}>
                    <h2>Betal online</h2>
                    <PaymentElement options={stripeOptions} />
                    <button id="submit" className="button-action button-confirm">Betal</button>
                    {errorMessage && <span id="paymentError" className="errorMessage">{errorMessage}</span>}
                </form>
            </div>
        </div>
    )
}