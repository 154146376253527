import ubLogo from "../ub_logo.png";
import {useOutlet} from "react-router-dom";
import {AuthProvider} from "../hooks/useAuth";
import {BookyProvider} from "../hooks/useBooky";
import {CompanyProvider} from "../hooks/useCompany";
import {Main} from "./Main";
import HeaderTools from "./HeaderTools";
import {useEffect} from "react";


export const Layout = ({portalData}) => {
    const outlet = useOutlet();
    return (
        <CompanyProvider
            initialCompanyID={portalData.companyID}
            loadedLocations={portalData.locations}
            loadedActivities={portalData.activities}
            initialPortalStyle={portalData}
        >
            <AuthProvider>
                <BookyProvider>
                    <div className="maincontainer">
                        <div className="header">
                            <div>
                                <a href="https://urban-golf.dk">
                                    <img alt="Logo" className="logo" src={ubLogo}/>
                                </a>
                            </div>
                            <div id="headerTools">
                                <HeaderTools />
                            </div>
                        </div>
                        <Main>
                            {outlet}
                        </Main>
                    </div>
                </BookyProvider>
            </AuthProvider>
        </CompanyProvider>
    )
}