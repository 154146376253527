import {useState} from "react";
import {useAuth} from "../../hooks/useAuth";
import {useCompany} from "../../hooks/useCompany";
import {getHost} from "../../utils/request-utils";
import {useBooky} from "../../hooks/useBooky";
import {useForm} from "react-hook-form";
import SignupForm from "./Signup";

export default function Login({onComplete}) {

    const [isSigningUp, setIsSigningUp] = useState(false);
    const {login} = useAuth();
    const {request, error, clearError, loading} = useBooky();
    const {companyID} = useCompany();
    const [isLoading, setIsLoading] = useState(false);
    const {register, clearErrors, getValues, handleSubmit, setError, formState: {errors}} = useForm();


    const performLogin = async function (inputData) {

        const data = {
            ...inputData,
            companyID: companyID,
        }

        try {
            setIsLoading(true);
            await login(data);
            onComplete(true);
        } catch (error) {
            switch (error.message) {
                case "User Not Found":
                    setError('password', {type: 'unknown'})
                    clearError();
                    break;
                default:
                    throw error;
            }
        } finally {
            setIsLoading(false);
        }
    }

    const forgotPassword = async function (e) {
        e.preventDefault();
        const email = getValues('email');
        if (email === "") {
            setError('email', {type: 'forgotrequied'})
            return;
        }
        try {
            setIsLoading(true);
            let u = new URL(getHost() + "/api/v1/forgotPassword");
            let data = {
                companyID: companyID,
                email: email,
            };
            await request('POST', u, data);
            alert("Vi har sendt en email med en ny engangs-adgangskode.")
            clearErrors();
        } catch (error) {
            switch (error.message) {
                case "User Not Found":
                    setError('email', {type: 'notfound'});
                    clearError();
                    break;
                default:
                    throw error;
            }
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div id="modalContainer" className={loading || isLoading ? 'spin' : null}>
            <div className="loginModal modal">
                <button className="close-button" onClick={() => onComplete(false)}>&#x00d7;</button>
                {!isSigningUp && (
                    <form className="loginForm" id="loginForm" onSubmit={handleSubmit(performLogin)} noValidate>
                        <h2>Log ind</h2>
                        <p>For at booke en tid eller købe et medlemskab, skal du logge ind. Hvis du ikke allerede har
                            en
                            bruger, kan du oprette en hurtigt og gratis.</p>
                        <label htmlFor="email">Email</label>
                        <input className={errors.email ? 'has-error' : null} {...register('email', {required: true})}/>
                        <span className="errorMessage">
                        {errors.email?.type === 'required' && <>Email skal angives</>}
                            {errors.email?.type === 'forgotrequied' && <>Indtast din email for at få en
                                engangs-adgangskode</>}
                            {errors.email?.type === 'notfound' && <>Der blev ikke fundet en bruger med denne email</>}
                        </span>

                        <label htmlFor="password">Adgangskode</label>
                        <input className={errors.password ? 'has-error' : null}
                               type="password" {...register('password', {required: true})}/>
                        <span className="errorMessage">
                        {errors.password?.type === 'required' && <>Vælg en adgangskode</>}
                            {errors.password?.type === 'unknown' && <>Forkert brugernavn eller adgangskode</>}
                        </span>

                        {error && <span className="errorMessage">{error}</span>}

                        <button type="submit" className="button-action button-confirm">Log ind</button>
                        <button className="button-action" id="btnForgotPassword" onClick={forgotPassword}>Glemt
                            adgangskode
                        </button>
                        <button className="button-action" id="btnShowCreateUser" onClick={() => {
                            setIsSigningUp(true)
                        }}>Opret ny bruger
                        </button>
                    </form>
                )}
                {isSigningUp && (
                    <SignupForm onComplete={(done) => {
                        setIsSigningUp(false);
                        if (done) {
                            onComplete()
                        }
                    }}/>
                )}

            </div>

        </div>
    )

}