import {getHost} from "../../utils/request-utils";
import {useCompany} from "../../hooks/useCompany";
import {useBooky} from "../../hooks/useBooky";
import {useAuth} from "../../hooks/useAuth";
import { useForm } from "react-hook-form";

export default function SignupForm({onComplete}) {
    const {companyID} = useCompany();
    const {setUser} = useAuth();

    const {request, error, clearError} = useBooky();
    const { register, handleSubmit, setError, formState: { errors } } = useForm();

    const performSignup = async function(inputData) {
        const data = {
            ...inputData,
            language: 'da',
            companyID: companyID,
            remember: true,
        };

        try {

            let u = new URL(getHost() + "/api/v1/users");
            const ret = await request('POST', u, data);

            setUser(ret.data.accessToken)
            onComplete(true);
        } catch (error) {
            switch (error.message) {
                case "User Already Exists":
                    setError("email", {type: "taken"});
                    clearError();
                    break;
            }
        }
    }

    return (
        <form className="loginForm" id="signupForm" onSubmit={handleSubmit(performSignup)} noValidate>
            <h2>Opret ny bruger</h2>
            <p>Det er hurtigt og gratis at oprette en bruger. Vi bruger kun dine oplysninger til at holde
                styr
                på din booking.</p>

            <label htmlFor="name">Navn</label>
            <input className={errors.name ? 'has-error' : null} {...register('name', { required: true })}/>
            <span className="errorMessage">
                            {errors.name?.type === 'required' && <>Navn skal angives</>}
                        </span>

            <label htmlFor="phoneNumber">Telefon</label>
            <input className={errors.phoneNumber ? 'has-error' : null} {...register('phoneNumber', { required: true })}/>
            <span className="errorMessage">
                        {errors.phoneNumber?.type === 'required' && <>Telefonnummer skal angives</>}
                        </span>

            <label htmlFor="email">Email</label>
            <input className={errors.email ? 'has-error' : null} {...register('email', { required: true, pattern: /.+@.+\..+/ })}/>
            <span className="errorMessage">
                        {errors.email?.type === 'required' && <>Email skal angives</>}
                        {errors.email?.type === 'pattern' && <>Email ser ikke gyldig ud</>}
                {errors.email?.type === 'taken' && <>Der findes allerede en bruger med denne email</>}
                        </span>

            <label htmlFor="password">Adgangskode</label>
            <input className={errors.password ? 'has-error' : null} type="password" {...register('password', { required: true })}/>
            <span className="errorMessage">
                        {errors.password?.type === 'required' && <>Vælg en adgangskode</>}
                        </span>

            {error && <span className="errorMessage">{error}</span>}
            <button type="submit" className="button-action button-confirm" id="btnSignup">Opret bruger
            </button>
            <button className="button-action" id="btnShowLogin" onClick={() => { onComplete(false)}}>Jeg har allerede en bruger</button>
        </form>
    )
}