import {useBooky} from "../../hooks/useBooky";
import {getHost} from "../../utils/request-utils";
import {useCompany} from "../../hooks/useCompany";
import { useEffect, useState} from "react";
import './memberships.css';
import {DateTime} from "luxon";
import ReactMarkdown from "react-markdown";
import {useAuth} from "../../hooks/useAuth";
import Login from "../Login/Login";


export default function MembershipsPage() {

    const {request, error, clearError} = useBooky();
    const {companyID} = useCompany();
    const [memberships, setMemberships] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const {user} = useAuth();
    const [loggingIn, setLoggingIn] = useState(false);

    function formatPrice(price, currency) {
        return new Intl.NumberFormat('da-DK', {style: 'currency', currency: currency}).format(price).toString();
    }

    useEffect(() => {
        let abort = false;
        const getData = async function() {
            if (memberships !== null) {
                return;
            }
            const u = new URL(getHost()+"/api/v1/memberships?companyID=" + companyID);
            const ret = await request('GET', u);
            if (abort) return;
            setMemberships(ret.data);

        }
        getData();
        return () => {abort = true}
    }, [companyID, memberships, request])

    if (memberships === null || memberships === undefined) {
        return (<></>);
    }

    const goCheckout = async function(membershipID) {

        if (!user) {
            setLoggingIn(true)
            return;
        }


        try {
            const u = new URL(getHost()+"/api/v1/stripeLink");
            const data = {action: "CheckoutLink", membershipID};
            const ret = await request('POST', u, data);
            document.location.href = ret.data.link;
        } catch (error) {
            switch (error.message) {
                case "User Already Has Membership":
                    setErrorMessage("Du har allerede et medlemskab. Kontakt os på info@urban-golf.dk eller telefon 93 98 00 17 for at ændre medlemsskabet.")
                    clearError();
                    break;
                default:
                    throw error;
            }
        }
    }

    return (
        <>
            <div className="detailsselector">
                <div>
                    Medlemskaber
                </div>
            </div>
            {error && <div id="membershipError" className="errorMessage">{error}</div>}
            {errorMessage && <div id="membershipError" className="errorMessage">{errorMessage}</div>}
            <div className="summaryContainer" id="membershipContainer" style={{justifyContent: "flex-start"}}>

                {memberships.map(membership => {
                    const purchasableFrom = membership.purchasableFrom ? DateTime.fromISO(membership.purchasableFrom) : null;
                    return (
                        <div key={membership.id} style={{maxWidth: 550}}>
                            <ReactMarkdown components={{h1:"h2"}}>{membership.description}</ReactMarkdown>
                            <div className="membershipPrice">
                                {formatPrice(membership.displayedPrice, membership.displayedCurrency)}{membership.priceFrequency==="Monthly" && <>/måned</>}
                                {membership.priceFrequency === "Once" && <span
                                    style={{fontSize: "50%", fontStyle: "italic ", fontWeight: "normal"}}>(engangsbeløb)</span>}
                            </div>
                            {purchasableFrom && purchasableFrom > DateTime.now() && <div className="soldOut">Sælges fra {purchasableFrom.toFormat("dd. LLLL",  {locale: "da"})}</div>}
                            {(!membership.soldOut && (!purchasableFrom || purchasableFrom < DateTime.now())) && <button className="button-action button-confirm" onClick={() => goCheckout(membership.id)}>Køb</button>}
                            {membership.soldOut && <div className="soldOut"><em>Udsolgt! <a href="https://urban-golf.dk/kontakt/">Skriv til os</a> for at komme på venteliste.</em></div>}
                        </div>
                    )
                })}

            </div>
            <div className="bottomDetails">
                <a target="_blank" rel="noreferrer" href="https://urban-golf.dk/betingelser-for-medlemskab/">Læs betingelser for
                    medlemskaber her</a><br/>
                Hvis du køber et medlemskab i anden halvdel af måneden (fra d. 15. og frem), betaler du for resten af indeværende måned + næste måned med det samme.<br />
                Bemærk: Der er 200,- i depositum på udleverede nøglebrikker. Dette beløb indgår ikke i abonnementet. Du kan hente din nøglebrik i vores åbningstid.
            </div>
            {loggingIn && <Login onComplete={(state) => { setLoggingIn(false); }} />}
        </>
    )
}